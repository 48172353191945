/* =============================================================================

Slideout Panel basic stylings and keyframe properties (The stylings for what
it looks like as the slideout panel is being translated into view).

============================================================================= */

.slideout-panel {
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  visibility: hidden;
  -webkit-transition: visibility 0s 0.6s;
  -moz-transition: visibility 0s 0.6s;
  transition: visibility 0s 0.6s;
}
.slideout-panel::after {
  /* overlay layer */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  cursor: pointer;
  -webkit-transition: background 0.3s 0.3s;
  -moz-transition: background 0.3s 0.3s;
  transition: background 0.3s 0.3s;
}
.slideout-panel.is-visible {
  visibility: visible;
  -webkit-transition: visibility 0s 0s;
  -moz-transition: visibility 0s 0s;
  transition: visibility 0s 0s;
}
.slideout-panel.is-visible::after {
  background: rgba(0, 0, 0, 0.6);
  -webkit-transition: background 0.3s 0s;
  -moz-transition: background 0.3s 0s;
  transition: background 0.3s 0s;
}
.slideout-panel.is-visible .slideout-panel-close::before {
  -webkit-animation: cd-close-1 0.6s 0.3s;
  -moz-animation: cd-close-1 0.6s 0.3s;
  animation: cd-close-1 0.6s 0.3s;
}
.slideout-panel.is-visible .slideout-panel-close::after {
  -webkit-animation: cd-close-2 0.6s 0.3s;
  -moz-animation: cd-close-2 0.6s 0.3s;
  animation: cd-close-2 0.6s 0.3s;
}

@-webkit-keyframes cd-close-1 {
  0%, 50% {
    -webkit-transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(45deg);
  }
}
@-moz-keyframes cd-close-1 {
  0%, 50% {
    -moz-transform: rotate(0);
  }
  100% {
    -moz-transform: rotate(45deg);
  }
}
@keyframes cd-close-1 {
  0%, 50% {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
@-webkit-keyframes cd-close-2 {
  0%, 50% {
    -webkit-transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(-45deg);
  }
}
@-moz-keyframes cd-close-2 {
  0%, 50% {
    -moz-transform: rotate(0);
  }
  100% {
    -moz-transform: rotate(-45deg);
  }
}
@keyframes cd-close-2 {
  0%, 50% {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
}

/* =============================================================================

Closing icon position and styling

============================================================================= */

.slideout-panel-close {
  content: "Close";
  color: $white;
  position: absolute;
  top: 0;
  right: 0;
  height: 60px;
  width: 60px;
  display: inline-block;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  background-color: transparent;
  z-index: 1;
}
.slideout-panel-close::before, .slideout-panel-close::after {
  content: "Close";
  position: absolute;
  top: 22px;
  left: 20px;
  height: 3px;
  width: 20px;
  /* this fixes a bug where pseudo elements are slighty off position */
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: $white;
}
.slideout-panel-close::before {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.slideout-panel-close::after {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.no-touch .slideout-panel-close:hover::before, .no-touch .slideout-panel-close:hover::after {
  background-color: $white;
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.no-touch .slideout-panel-close:hover::before {
  -webkit-transform: rotate(220deg);
  -moz-transform: rotate(220deg);
  -ms-transform: rotate(220deg);
  -o-transform: rotate(220deg);
  transform: rotate(220deg);
}
.no-touch .slideout-panel-close:hover::after {
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

/* =============================================================================

SLideout Panel Container

============================================================================= */

.slideout-panel-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  background: #ffffff;
  z-index: 1;
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-delay: 0.3s;
  -moz-transition-delay: 0.3s;
  transition-delay: 0.3s;
  -webkit-box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.5);
  -moz-box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.5);
  box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.5);
  overflow-x: scroll;
}
.from-right .slideout-panel-container {
  right: 0;
  -webkit-transform: translate3d(100%, 0, 0);
  -moz-transform: translate3d(100%, 0, 0);
  -ms-transform: translate3d(100%, 0, 0);
  -o-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}
.from-left .slideout-panel-container {
  left: 0;
  -webkit-transform: translate3d(-100%, 0, 0);
  -moz-transform: translate3d(-100%, 0, 0);
  -ms-transform: translate3d(-100%, 0, 0);
  -o-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}
.is-visible .slideout-panel-container {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
}
@media only screen and (max-width: 768px) {
  .slideout-panel-container {
    width: 100%;
  }
}
@media only screen and (max-width: 1170px) {
  .slideout-panel-container {
    width: 100%;
  }
}

.slideout-panel-content {
  padding: 20px 30px;
}
.slideout-panel-content:after {
    clear: both;
}
.slideout-panel-content:before, .slideout-panel-contentr:after {
    content: " ";
    display: table;
}


/* Deprecated styles ===========================================================

.slideout-panel-header {
  position: fixed;
  width: 50%;
  height: auto;
  z-index: 2;
  -webkit-transition: top 0.3s 0s;
  -moz-transition: top 0.3s 0s;
  transition: top 0.3s 0s;
  background-color: #D52027;
  color: $white;
}
.slideout-panel-header .slideout-panel-title {

}
.from-right .slideout-panel-header, .from-left .slideout-panel-header {
  top: -50px;
}
.from-right .slideout-panel-header {
  right: 0;
}
.from-left .slideout-panel-header {
  left: 0;
}
.is-visible .slideout-panel-header {
  top: 0;
  -webkit-transition: top 0.3s 0.3s;
  -moz-transition: top 0.3s 0.3s;
  transition: top 0.3s 0.3s;
}
@media only screen and (max-width: 768px) {
  .slideout-panel-header {
    width: 70%;
  }
}
@media only screen and (max-width: 1170px) {
  .slideout-panel-header {
    width: 85%;
  }
}
.slideout-panel-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-top: 20%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
@media only screen and (max-width: 990px) {
  .slideout-panel-content {
    padding-top: 42%;
  }
}
@media only screen and (max-width: 768px) {
  .slideout-panel-content {
    padding-top: 62%;
  }
}
@media only screen and (max-width: 480px) {
  .slideout-panel-content {
    padding-top: 85%;
  }
}
.slideoutPanelSectionTitle {
  font-family: $font;
  text-transform: uppercase;
  font-weight: 900;

}
.slideout-panel-content p:first-of-type {
  margin-top: 0;
}
@media only screen and (min-width: 768px) {
  .slideout-panel-content p {
    font-size: 16px;
    line-height: 1.6;
  }
}

============================================================================= */
