.button-block, a.button-block
{
	display: block;

}

.button-small, a.button-small
{
	padding: 0.8em;
	font-size: 0.8em;
}

.button-medium, a.button-medium
{
	padding: 1em;
	font-size: 1em;
}

.button-large, a.button-large
{

	padding: 1.2em;
	font-size: 1.2em;

}

.button-center, a.button-center {
    text-align: center;
}
