.pager {
    clear: both;
    text-align: center;
}

.pager li {
    background-image: none;
    display: inline;
    list-style-type: none;
    padding: 0.5em;
}

.pager-current {
    font-weight: bold;
}