
  table.unity-table.table thead > tr > th {
    padding: 1em 0;
    border-bottom: 1px solid #ddd;
  }
  table.unity-table.table > tbody > tr > th {
    padding: 1em 0;
  }
  table.unity-table.table > tbody > tr > td {
    padding: 1em 0;

    }
