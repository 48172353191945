////////////////////////
// Libraries
////////////////////////

@import "libraries/university-icons/university-icons";
@import "libraries/university-icons/university-icons-customizations";
//@import "libraries/superfish/superfish";

/*@import "libraries/sidr/sidr-light";*/

@import "libraries/flexslider/flexslider";
@import "libraries/flexslider/flexslider-customizations";

//@import "libraries/hover-master/hover-min";

@import "libraries/tablesaw/tablesaw";

@import "libraries/slick-slider/slick-slider";
@import "libraries/slick-slider/slick-theme";

@import "libraries/prettify/prettify";

@import "libraries/nivo-lightbox/nivo-lightbox";
@import "libraries/nivo-lightbox/themes/default/default";

@import "libraries/count-to/count-to";

@import "libraries/animate/animate";

@import "libraries/fullcalendar/fullcalendar";
@import "libraries/fullcalendar/fullcalendar-override";

//@import 'third-party/bootstrap/bootstrap-theme';
//@import 'third-party/bootstrap/bootstrap-fixes';
