//https://github.com/sixfootsixdesigns/Bootstrap-3-Grid-Columns-Clearing

// clear first in row in ie 8 or lower
.multi-columns-row .first-in-row {
    clear: left;
}

// clear the first in row for any block that has the class "multi-columns-row"
.multi-columns-row .col-xs-6:nth-child(2n + 3) {
    clear: left;
}

.multi-columns-row .col-xs-4:nth-child(3n + 4) {
    clear: left;
}

.multi-columns-row .col-xs-3:nth-child(4n + 5) {
    clear: left;
}

.multi-columns-row .col-xs-2:nth-child(6n + 7) {
    clear: left;
}

.multi-columns-row .col-xs-1:nth-child(12n + 13) {
    clear: left;
}

@media (min-width: $screen-sm-min) {
    // reset previous grid
    .multi-columns-row .col-xs-6:nth-child(2n + 3) {
        clear: none;
    }

    .multi-columns-row .col-xs-4:nth-child(3n + 4) {
        clear: none;
    }

    .multi-columns-row .col-xs-3:nth-child(4n + 5) {
        clear: none;
    }

    .multi-columns-row .col-xs-2:nth-child(6n + 7) {
        clear: none;
    }

    .multi-columns-row .col-xs-1:nth-child(12n + 13) {
        clear: none;
    }

    // clear first in row for small columns
    .multi-columns-row .col-sm-6:nth-child(2n + 3) {
        clear: left;
    }

    .multi-columns-row .col-sm-4:nth-child(3n + 4) {
        clear: left;
    }

    .multi-columns-row .col-sm-3:nth-child(4n + 5) {
        clear: left;
    }

    .multi-columns-row .col-sm-2:nth-child(6n + 7) {
        clear: left;
    }

    .multi-columns-row .col-sm-1:nth-child(12n + 13) {
        clear: left;
    }
}

@media (min-width: $screen-md-min) {
    // reset previous grid
    .multi-columns-row .col-sm-6:nth-child(2n + 3) {
        clear: none;
    }

    .multi-columns-row .col-sm-4:nth-child(3n + 4) {
        clear: none;
    }

    .multi-columns-row .col-sm-3:nth-child(4n + 5) {
        clear: none;
    }

    .multi-columns-row .col-sm-2:nth-child(6n + 7) {
        clear: none;
    }

    .multi-columns-row .col-sm-1:nth-child(12n + 13) {
        clear: none;
    }

    // clear first in row for medium columns
    .multi-columns-row .col-md-6:nth-child(2n + 3) {
        clear: left;
    }

    .multi-columns-row .col-md-4:nth-child(3n + 4) {
        clear: left;
    }

    .multi-columns-row .col-md-3:nth-child(4n + 5) {
        clear: left;
    }

    .multi-columns-row .col-md-2:nth-child(6n + 7) {
        clear: left;
    }

    .multi-columns-row .col-md-1:nth-child(12n + 13) {
        clear: left;
    }
}

@media (min-width: $screen-lg-min) {
    // reset previous grid
    .multi-columns-row .col-md-6:nth-child(2n + 3) {
        clear: none;
    }

    .multi-columns-row .col-md-4:nth-child(3n + 4) {
        clear: none;
    }

    .multi-columns-row .col-md-3:nth-child(4n + 5) {
        clear: none;
    }

    .multi-columns-row .col-md-2:nth-child(6n + 7) {
        clear: none;
    }

    .multi-columns-row .col-md-1:nth-child(12n + 13) {
        clear: none;
    }

    // clear first in row for large columns
    .multi-columns-row .col-lg-6:nth-child(2n + 3) {
        clear: left;
    }

    .multi-columns-row .col-lg-4:nth-child(3n + 4) {
        clear: left;
    }

    .multi-columns-row .col-lg-3:nth-child(4n + 5) {
        clear: left;
    }

    .multi-columns-row .col-lg-2:nth-child(6n + 7) {
        clear: left;
    }

    .multi-columns-row .col-lg-1:nth-child(12n + 13) {
        clear: left;
    }
}
