.vertical-timeline {
    list-style: none;
    padding: 20px 0 20px;
    position: relative;
    list-style-type: none;
}

.vertical-timeline li {
    list-style: none;
    list-style-type: none;
    margin: 0;
}

.vertical-timeline:before {
    top: 0;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 3px;
    background-color: #eeeeee;
    left: 50%;
    margin-left: -1.5px;
    margin-bottom: 20px;
}

.vertical-timeline--item--content-wrap {
    #{$theme-selectors} {
        color: #000;
    }
}

.vertical-timeline > li {
    margin-bottom: 20px;
    position: relative;
}

.vertical-timeline > li:before,
.vertical-timeline > li:after {
    content: " ";
    display: table;
}

.vertical-timeline > li:after {
    clear: both;
}

.vertical-timeline > li:before,
.vertical-timeline > li:after {
    content: " ";
    display: table;
}

.vertical-timeline > li:after {
    clear: both;
}

.vertical-timeline > li > .vertical-timeline--item-content-wrap {
    width: 46%;
    float: left;
    border: 1px solid #d4d4d4;
    border-radius: 2px;
    padding: 20px;
    position: relative;
    -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
    background-color: #ccc;
}

.vertical-timeline > li > .vertical-timeline--item-content-wrap:before {
    position: absolute;
    top: 26px;
    right: -15px;
    display: inline-block;
    border-top: 15px solid transparent;
    border-left: 15px solid #ccc;
    border-right: 0 solid #ccc;
    border-bottom: 15px solid transparent;
    content: " ";
}

.vertical-timeline > li > .vertical-timeline--item-content-wrap:after {
    position: absolute;
    top: 27px;
    right: -14px;
    display: inline-block;
    border-top: 14px solid transparent;
    border-left: 14px solid #ccc;
    border-right: 0 solid #ccc;
    border-bottom: 14px solid transparent;
    content: " ";
}

.vertical-timeline > li > .vertical-timeline--item-icon {
    color: #fff;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 1.4em;
    text-align: center;
    position: absolute;
    top: 16px;
    left: 50%;
    margin-left: -25px;
    background-color: #999999;
    z-index: 100;
    border-top-right-radius: 50%;
    border-top-left-radius: 50%;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%;
}

.vertical-timeline > li:nth-child(even) > .vertical-timeline--item-content-wrap,
.vertical-timeline > li.vertical-timeline--item__right > .vertical-timeline--item-content-wrap {
    float: right;
}

.vertical-timeline > li:nth-child(even) > .vertical-timeline--item-content-wrap:before,
.vertical-timeline > li.vertical-timeline--item__right > .vertical-timeline--item-content-wrap:before {
    border-left-width: 0;
    border-right-width: 15px;
    left: -15px;
    right: auto;
}

.vertical-timeline > li:nth-child(even) > .vertical-timeline--item-content-wrap:after,
.vertical-timeline > li.vertical-timeline--item__right > .vertical-timeline--item-content-wrap:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto;
}

.vertical-timeline--item-heading {
    margin-top: 0;
    color: inherit;
}

.vertical-timeline--item-body > p,
.vertical-timeline--item-body > ul {
    margin-bottom: 0;
}

.vertical-timeline--content > p + p {
    margin-top: 5px;
}