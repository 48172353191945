.list-card {
    background: #1f0000;
    border: 4px solid #ebcccc;
    padding: 20px 30px;
    max-width: 500px;
}

.list-card--heading {
    @extend .card--heading;
    color: #fff;
    font-size: 1.2em;
    margin: 0 0 20px 5px;
    text-transform: uppercase;
    border-bottom: 1px solid #fff;
    text-indent: -5px;
    width: auto;
    display: inline-block;
    padding: 0 5px 0 0;
}

.list-card--icon {
    @extend .card--icon;
    color: #fff;
    font-size: 1.4em;
}

.list-card--image {
    @extend .card--image;
}

.list-card--content {
    color: #fff;
    a {
        color: #fff;
    }
}