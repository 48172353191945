.event-card {
  margin-bottom: 2em;
  background-color: $white;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);

  &:hover,
  &:focus {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  }
}
.event-card--heading {
    color: $white;
    font-size: 1.1em;
    font-weight: 700;
    text-transform: uppercase;
    background-color: $red;
    padding: 1em;
}
.event-card--heading-link, a.event-card--heading-link {
  color: $white;

  &:hover,
  &:focus {
    color: $white;
    transition: .3s ease all;
  }
  &:visited {
    color: $white;
  }
  &:visited:hover {
    color: $white;
  }
}

.event-card--content {
    padding: 1em;
    font-size: 1em;
    line-height: 1.5;
    a {
        color: $red;

      &:hover,
      &:focus {
        transition: .3s ease all;
      }
    }

}

.event-card--date-time:before {
   @extend .sbuicon;
    color: $red;
    content: "\ea4d";
    float: left;
    width: 2em;
    text-indent: -2em;
    margin-top: 0.2em;
}

.event-card--map:before {
    @extend .sbuicon;
    color: $red;
    content: "\ea38";

    float: left;
    width: 2em;
    text-indent: -2em;
    margin-top: 0.2em;
}

.event-card--audience:before {
    @extend .sbuicon;
    color: $red;
    content: "\eaf4";
    float: left;
    width: 2em;
    text-indent: -2em;
    margin-top: 0.2em;
}

.event-card--register {

}

.event-card--button {
  @extend .default-button;
}

.event-card--button:hover {
  @extend .default-button:hover;
}

.event-card--registration-button:before {
    @extend .sbuicon;
    content: "\ed5c";
    margin-right: 0.75em;
}

.event-card--add-to-cal-button:before {
    @extend .sbuicon;
    content: "\f073";
    margin-right: 0.75em;
}
