

.background-image-card {
    position: absolute;
    height: 100%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
}



.image-card {
  position: fixed;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
}
.image-card--image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  min-width: 50%;
  min-height: 50%;
  overflow-x: hidden;
}
