.circle-image img,
img.circle-image {
    margin-bottom: 20px;
    display: block;
    border: 0px solid #eee;
    border-radius: 50%;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 1);
    max-width:100%;
}


img.circle-image:hover {
    box-shadow: 0px 0px 14px rgba(153, 0, 0, 1);
}

img.flat-circle-image {
    border-radius: 50%;
    max-width:100%;
}





img{
    max-width: 100%;
    height: auto;
}


img.allow-spill{
    max-width: none;
}


.img-responsive,
{
  display: block;
  width: 100%;
  height: auto;
}
