.secondary-button {
    color: #93e6fa;
    font-family: $font;
    text-transform: uppercase;
    font-weight: normal;
    display: block;
    padding: 12px 7px;
    text-shadow: none;
    margin: 0 1% 5px;
    border-radius: 4px;
    text-align: center;
    font-size: 1em;
    background: #188cdf;
    background: rgba(24, 140, 223, .8);
    border: 1px solid rgba(24, 140, 223, .2);
    cursor: pointer;
    @extend .button-trans;
    
    &:hover,
    &:focus {
        background: rgba(24, 140, 223, .9);
        border: 1px solid #188cdf;
        color: #93e6fa;
    }

    &.selected {
        border: 3px solid rgba(128, 219, 240, 0.5);
        padding: 10px 4px;
    }
}