
.quick-nav--search-container {
  display: inline-block;
}
.quick-nav--search-input {
  font-size: 11px;
  height: 21px;
  box-shadow: none;
  border: 1px solid #ddd;
  background: #ddd;
  border-radius: 20px;
  width: 100%;
  padding-left: 10px;
  color: #000;
  display: inline-block;

    ::-webkit-input-placeholder {
        color: red !important;
    }


}


.quick-nav--search-input::-webkit-input-placeholder {
color: #000;
}

.quick-nav--search-input:-moz-placeholder { /* Firefox 18- */
color: #000;
}

.quick-nav--search-input::-moz-placeholder {  /* Firefox 19+ */
color: #000;
}

.quick-nav--search-input:-ms-input-placeholder {
color: #000;
}

//TODO HACKS
.quick-nav--search-button {
  display: none;
}

.quick-nav--search-button .sbuicon-search {
    margin-top: -2px;
    display: block;
}



.quick-nav--container {
  background-color: #111;
  border: 0;
  border-radius: 0;
  text-transform: none;
  font-size: .875em;
  width: 100%;
  margin: 0;
  padding: 0;

  @include breakpoint($screen-md) {

      padding: .5em;
      border-bottom: 1px solid #ccc;
      border-left: 1px solid #ccc;
      border-right: 1px solid #ccc;
      border-bottom-left-radius: 1em;
      border-bottom-right-radius: 1em;
      color: $dark-gray;
      font-weight: 700;
      text-transform: uppercase;
      font-size: .78em;
      background-color: #fff;

  }
}

.quick-nav--link, a.quick-nav--link, a.quick-nav--link:visited {
padding: 10px 5px;
 color: #fff;
display: block;



    @include breakpoint($screen-md) {
         color: $dark-gray;
         display: inline;
         padding: 0;
         &:hover,
         &:focus {
           color: $red;
         }
         &:visited {
           //color: #000;
         }
    }

}


.quick-nav--list {
  margin-bottom: 0 !important;

  @include breakpoint($screen-md) {

    margin: 0 !important;
    padding: 0 !important;

  }
}
// Removes default left-margin on ul tag
ul.quick-nav--list {
  margin-left: 0 !important;
}

.quick-nav--list li:last-of-type {
  @include breakpoint($screen-md) {
      border-right: 0 !important;
  }
}

.quick-nav--list li:nth-child(even) {
    border-right: 0;

    @include breakpoint($screen-md) {
        border-right: 1px solid #4b4b4b;
    }
}
.quick-nav--list-item {

    width: 50%;
    display: inline-block;
    list-style: none;
    border-right: 1px solid #4b4b4b;
    text-align: center;
    margin: 0 -1px 0 -1px;

    box-sizing: border-box;


    @include breakpoint($screen-md) {

        display: inline;
        list-style: none;
        margin-right: .5em;
        padding: 0 1em;



    }

    &:hover, &focus {

        background-color: $red;

        .quick-nav--link {
                color: $white;
        }

        @include breakpoint($screen-md) {
            background-color: #fff;

            .quick-nav--link {
                color: $red;
            }
        }
    }

}
