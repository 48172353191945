

.footer-nav{
    color: $black;
    margin-bottom: 2em;
    strong{
        text-transform: uppercase;
            margin: 20px 0 5px 0;
    }
    ul, ul li
    {
    	margin: 0;
        padding: 0;
        list-style: none;
        list-style-type: none;
    }

    ul li{
        margin: 0 5.5% 2px 0;
    }

    ul ul
    {
    	margin-left: 1em;
    }

    ul li a{
        padding: 5px 0 3px 0;
        font-weight: 500;
        text-decoration: none;
    }
    a:hover{
        text-decoration: none;
        border-bottom: 2px solid $red;
        color: $red;

    }
}

.dark-footer {

    .footer-nav{
        color: $light-gray;


        ul li a{
            color: $white;
            text-decoration: none;
        }
        a:hover{
            border-bottom: 2px solid $white;
            text-decoration: none;
            color: $white;

        }
    }


}
