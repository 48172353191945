h1, h2, h3, h4, h5, h6 {
    font-weight: 900;
    margin: 1em 0;

    &.cap {
        text-transform: capitalize;
    }

    &.small-title-1 {
        text-align: center;
        padding: 20px 0;
        margin: 25px 0;
    }
}

h2.small-title-1 {
    font-size: 1.3em;
    font-weight: normal;
    //font-family: 'Trade Gothic Next W01';
    letter-spacing: .025em;
    word-spacing: .025em;
    text-align: center;

    @include breakpoint($screen-md) {
        margin: 20px 0 40px;
    }
}

h1.site-name {
    font-size: 1.8em;
    display: block;
    width: 100%;
    text-align: center;
    font-family: $font;
    font-weight: 400;
    text-transform: none;
    color: #000;
    margin-top: 19px !important;

    @include breakpoint($screen-md) {
        font-size: 2em;
        float: left;
        width: auto;
        display: inline-block;
        padding: 0;
        margin: 5px 0 8px 0;
    }

    a {
        color: #000;
        font-family: $font;
        margin: 0 0 20px;
        padding: 0 10px;

        @include breakpoint($screen-md) {

            width: 585px;
            text-align: left;
            margin: 7px 0 0;
            border-left: 1px solid #333;
            padding: 0 0 0 16px;
            line-height: 1;
        }
    }

    a:hover {
        text-decoration: none;
    }
}


h1,
h2,
h3,
h4,
h5 {
  //  font-family: 'ITCFranklinGothicW01-Md 812695', Helvetica, Helvetica Neue, Arial, sans-serif;
    font-weight: 900;
    width: 100%;
    display: block;
    margin: 0.5em 0 0.5em 0;
    text-rendering: optimizeLegibility;
}

h1.serif,
h2.serif,
h3.serif,
h4.serif,
h5.serif {
    //font-family: 'CenturyExpandedLTW01-Ro' !important;
    font-weight: 300;
    text-transform: none;
    color: #000;
}

.subtitle {
    display: block;
  //  font-family: 'CenturySchoolbookW01-It 927458';
    font-weight: 400;
    cursor: default;
    margin: 20px 0;
    padding: 0;
}

.caption {
  //  font-family: 'CenturySchoolbookW01-It 927458';
}

.mega {
    font-size: 4.85em;
}

h1, .heading1 {
    font-size: 3.3em;
}

h2, .heading2 {
    font-size: 2.5em;
}

h3, .heading3 {
    font-size: 1.9em;
}

h4, .heading4 {
    font-size: 1.6em;
}

h5, .heading5 {
    font-size: 1.1em;
}

.subtitle {
    font-size: 1.2em;
}

.sbu-text {
    font-size: 1em;
    color: #222;
    line-height: 1.5em;
}

h2.small-title-1 {
    font-size: 1.3em;
    font-weight: normal;
  //  font-family: 'Trade Gothic Next W01';
    letter-spacing: .025em;
    word-spacing: .025em;
}

h2.small-title-2 {
    font-size: 1.5em;
    font-weight: normal;
  //  font-family: 'CenturySchoolbookW01-It 927458';
    text-transform: none;
    margin: 0;
}

h2.small-title-3 {
    font-size: 1.5em;
    font-weight: normal;
//    font-family: 'TradeGothicW01-BoldCn20 675334';
    text-transform: none;
    margin: 0;
}

h3.medium-title-2 {
    font-size: 1.3em;
    font-weight: normal;
//    font-family: 'Trade Gothic Next W01';
    text-transform: none;
    margin: 0;
}

.medium-title-3 {
    font-size: 1.8em;
//    font-family: 'Trade Gothic W01 Bold 2';
    text-transform: none;
}

.content-header {
    padding: 0.5em 0 0.5em;
    color: #900;
    border-bottom: 1px solid #777;
}


i.heading-icon {
    display: block;
    margin-bottom: 2em;
}


.bold-introduction-heading {
  line-height: .8em;
  text-transform: uppercase;
  font-size: 4em;
  font-weight: 900;
  color: $red;
  padding-right: 3rem;
  margin-bottom: 1rem;
}

.section-heading {
    margin-bottom: 1em;
}


.stripes-heading {
    background: #900;
    background: url(../images/backgrounds/rays/headers/header-stripe.png) 0 0 no-repeat;
    background-size: cover;
    color: #fff;
    letter-spacing: 0.005em;
    //font-size: 20px;
    font-weight: 400;
    position: relative;
    line-height: 1em;
    text-rendering: optimizeLegibility;
    font-family: 'Museo Slab W01';
    padding: 16px 10px;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 0 10px;
    font-size: 1.25em;
}