.simple-blurb-card {
    /* padding: 2em; */
    margin-bottom: 2em;
  }

.simple-blurb-card--icon {
    @extend .card--icon;
}

.simple-blurb-card--image {
	@extend .card--image;
	/* padding: 10px; */
	/* border: 1px solid #eee; */
	/* margin-bottom: 15px; */
}

.simple-blurb-card--heading {
    /* @extend .card--heading; */
    font-weight: 700;
    line-height: 1em;
}

.simple-blurb-card--subheading {
    @extend .card--subheading;
    line-height: 1em !important;
}

.simple-blurb-card--text {
    /* @extend .card--text; */
    font-size: 1em;
    line-height: 1.5em;
}

.simple-blurb-card--cta {

    margin-top: 1em;
}

.simple-blurb-card--video-container {
	@extend .embed-responsive;
	@extend .embed-responsive-16by9;

	border: 1px solid #eee;
}

.simple-blurb-card--video {
	@extend .embed-responsive-item;

	padding: 10px;
	margin-bottom: 15px;
}
