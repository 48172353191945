.alert-bar {
  background-color: $dark-gray;
  padding: 1em 20px;
  color: $white;
  font-weight: 700;
  text-transform: uppercase;
  position: relative;
  z-index: 2;
  font-size: .8em;
  line-height: 1.2em;
  //border-radius: 3px;
  //margin-top: 1rem;
//display: inline-block;
      box-shadow:0px -10px 10px -10px rgba(0,0,0,0.5) inset;
    //TODO: Inner Shadow.

  a {
    color: $white;
    border-bottom: 2px solid #fff;

        &:hover,
        &:focus {
          border-bottom: none;
          text-decoration: none;
        }
    }

    @include breakpoint($screen-sm) {
        font-size: 1em;
    }
}
