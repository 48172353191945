.hero-bottom-fade {
    position: relative;
}

.hero-bottom-fade--content-wrap {
    margin: 0;
    padding: 1em 2em;
    background: rgba(255, 255, 255, 0.4);
    color: #fff;
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 10%, rgba(0, 0, 0, 0.85) 50%, rgba(0, 0, 0, 1) 100%);
    /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0)), color-stop(10%, rgba(0, 0, 0, 0.5)), color-stop(50%, rgba(0, 0, 0, 0.85)), color-stop(100%, rgba(0, 0, 0, 1)));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 10%, rgba(0, 0, 0, 0.85) 50%, rgba(0, 0, 0, 1) 100%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 10%, rgba(0, 0, 0, 0.85) 50%, rgba(0, 0, 0, 1) 100%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 10%, rgba(0, 0, 0, 0.85) 50%, rgba(0, 0, 0, 1) 100%);
    /* IE10+ */
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 10%, rgba(0, 0, 0, 0.85) 50%, rgba(0, 0, 0, 1) 100%);
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#000000',GradientType=0 );
    /* IE6-9 */
}

.hero-bottom-fade--content-text {
    max-width: 1200px;
    margin: 0 auto;
    color: #fff;
}

.hero-bottom-fade--content-heading {
    font-size: 2.5em;
}

.hero-bottom-fade--content-text a {
    color: #fff;
}

.hero-bottom-fade--content-text p a {
    color: #ccc;
    border-bottom: 1px dashed #ccc;
    text-decoration: none;
}
