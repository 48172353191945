/* -------------------------------------------------------------------
Count to
------------------------------------------------------------------- */

.count-item {
	text-align: center;
}

.count-icon {
	line-height: 1.2;
	font-size: 42px;
}