.farbeyond-event-button {
    display: block;
    width: 100%;
    position: relative;
    color: #fff;
    margin-bottom: 2em;
}

.farbeyond-event-button:hover, .farbeyond-event-button:focus {
    color: #fff;
}

 .farbeyond-event-button--title {
      font-family: "Effra W01", Helvetica, sans-serif;
      font-weight: 700;
    text-decoration: none;
      font-size: 1.025rem;
      line-height: 1em;
      padding-right: 5px;
      display: inline-block;



      display: inline-block;
      position: absolute;
      left: 0;
      padding: 7px 0 0 84px;
      right: 0;
      min-height: 67px;
      border-top: 2px solid transparent;
      top: -1px;
}

.farbeyond-event-button--date {
    text-transform: uppercase;
    width: 66px;
    height: 64px;
    margin: 0 0 0 0;

    background: #fff;
    color: #900;
    padding: 3px 0 0 0;
    transition: all .3s ease;
}


.farbeyond-event-button:hover .farbeyond-event-button--title {
    border-color: #fff;
    background: rgba(0,0,0,0.05);
}

.farbeyond-event-button--month, .farbeyond-event-button--day, {
    display: block;
    text-align: center;
}

.farbeyond-event-button--day {
    font-size: 2rem;
}

  .whatsComingUp ul li:hover .eventDate {
    color: #b00;
  }
  .whatsComingUp ul li a .eventTitle {
    color: #f4f4f4;
  }
  .whatsComingUp ul li:hover a .eventTitle {
    color: #fff;
  }
