/* =============================================================================

Video Header Stylings

============================================================================= */

.youtube-container {
  display: block;
  margin: 20px auto;
  width: 100%;
  max-width: 100%;
}
.youtube-player {
  display: block;
  width: 100%; /* assuming that the video has a 16:9 ratio */
  padding-bottom: 56.25%;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  cursor: hand;
  cursor: pointer;
  display: block;
}
img.youtube-thumb {
  bottom: 0;
  display: block;
  left: 0;
  margin: auto;
  max-width: 100%;
  width: 100%;
  position: absolute;
  right: 0;
  top: 0;
  height: auto;
}
div.play-button {
  height: 72px;
  width: 72px;
  left: 50%;
  top: 50%;
  margin-left: -36px;
  margin-top: -36px;
  position: absolute;
  background: url("http://i.imgur.com/TxzC70f.png") no-repeat;
  z-index: 99;
}
#youtube-iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}


/* =============================================================================

** Special Orientation Stylings **
----------------------------------

These styles do not appear anywhere else in the Unity Lab.
In the interest of saving time, I've added these classes in the hopes they
could eventually be repurposed throughout the project.

As of right now, I am unsure of the proper way to include these special classes
into the project as a whole.

============================================================================= */

// .no-scroll is added to the body tag when slideout panels are translated into view
// this prevents double scrolling on the body as well as the slideout panel

.no-scroll {
margin: 0;
height: 100%;
overflow: hidden
}

// .redRaysBorder creates a 'border' with a height of 25px featuring the new far beyond red rays.

.redRaysBorder {
  z-index: 99999;
  height: 25px;
  background-size: cover;
}

// .bg-farbeyondred sets the backgriund color to the new red backgroound color

.bg-farbeyondred {
  background-color: #D52027;
}

.bg-space-blue {
  background-color: #1a2549;
}

// Specific stylings for links in the 'fixed-nav'

.fixedNavLink {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
}

// .gradientOverlayTopRight creates a gradient effect stemming from the top right of the section using the :before pseudo element.

.gradient-overlay__TopRight {
  position: relative;
}
.gradient-overlay__TopRight:before {
  content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-image: linear-gradient(to bottom left,#000000,transparent);
	opacity: .6;
  z-index: -1;
}
.gradient-overlay__topLeft {
  position: relative;
}
.gradient-overlay__topLeft:before {
  content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-image: linear-gradient(to bottom right,#000000,transparent);
	opacity: .6;
  z-index: -1;
}
.continue-arrow {
  font-size: 3em;
  color: rgba(255, 255, 255, 0.5);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-radius: 50%;
  border: 1px solid;
  border-color: rgba(255, 255, 255, 0.5);
  padding: 8px 0px 0 10px;

  &:hover,
  &:focus {
    -webkit-transform: translateY(5px);
    -ms-transform: translateY(5px);
    transform: translateY(5px);
    color: white;
    border: 1px solid;
    border-color: rgba(255, 255, 255, 1);
  }
}

// These classes are meant to override exisiting margin/padding on already defined elements

.noMarginTop {
  margin-top: 0 !important;
}
.noMarginBottom {
  margin-bottom: 0 !important;
}
.noMarginLeft {
  margin-left: 0 !important;
}
.noMarginLeftRight {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.noPadding {
  padding: 0 !important;
}
.noBorder {
  border: 0 !important;
}
@media (max-width: 768px) {
  .noPaddingmobile {
    padding: 0 !important;
  }
}


// Changes text alignment based on viewport size

@media(max-width: 990px) {
	.text-left-mobile {
		text-align: left;
	}
	.text-center-mobile {
		text-align: center;
	}
}

// Special heading/subheading styles specific to the Orientation guide.

.heading {
	font-family:'Effra W01';
	line-height: 1.3;
  font-weight: 700;
}
.subheading {
	font-family:'Effra W01';
	line-height: 1.5;
  font-weight: 400;
}
.normal-text {
  font-size: 1.5em;
  line-height: 1.75;
}
.heading-small {
  font-size: 2em;
}
.heading-medium {
  font-size: 3em;
}
.heading-large {
  font-size: 5em;
}
.heading-extra-large {
  font-size: 8em;
}
@media(max-width:768px) {
  .heading-extra-large {
    font-size: 2em;
  }
}


.heading-super-large {
  font-size: 10em;
}

.side-padding {
  padding-left: 30px;
  padding-right: 30px;
}
.uppercase {
  text-transform: uppercase;
}

.text-blurb {
  padding: 10px 0 10px 0;
}

/* =============================================================================


** Fixed Navbar Stylings **


============================================================================= */

.navbar--fixed {
	position: relative;
	padding-top: 50px;
	transition: all 0.3s ease;
	box-shadow:0 3px 5px rgba(0,0,0,.05);
	padding:20px 0;
}

// Using these classes to give us the ability to separate the stylings of the fixed
// navbar from the bootstrap default nav   in the future. For now much of the defualt
// navbar stylings work for this case.
// The @extend function allows us to keep the default stylings of the bootstrap classes
// while also giving us the ability to modify them without changing the original
// bootstrap classes.

.navbar--fixed__default {
  @extend .navbar-default;

}
.navbar--fixed__links {
  @extend .navbar-nav;
  margin: 0 !important;
}
.navbar--fixed__header {
  @extend .navbar-header;
}
.navbar--fixed__collapse {
  @extend .navbar-collapse;

}
.navbar--fixed__header, .navbar--fixed__collapse {
	margin-top:10px;
  padding-left: 0 !important;
}

.navbar--fixed__default .navbar--fixed__links > li {
	margin-right: 1.5em;
}

.navbar--fixed__default .navbar--fixed__links > li > a {
	color:#bbb;
}
.navbar--fixed__default .navbar--fixed__links > li > a:after {
	position: relative;
	display: block;
	width:0%;
	height:3px;
	background:#D52027;
	content:' ';
	margin:0 auto;
	margin-top:3px;
	transition: all 0.3s ease;
}
.navbar--fixed__default .navbar--fixed__links > li > a:hover, .navbar--fixed__default .navbar--fixed__links > li.active > a {
	color: #484d53;
}
/* -----------------------------------------------------------------------------

Navbar media queries

----------------------------------------------------------------------------- */

@media(max-width:768px) {

	.navbar--fixed__default .navbar--fixed__links {
		padding-top:30px;
	}
}
@media(min-width:768px) {

	.navbar--fixed__default .navbar--fixed__links > li > a:hover:after,
	.navbar--fixed__default .navbar--fixed__links > li > a:focus:after, .navbar--fixed__default .navbar--fixed__links > li.active > a:after {
	  		width:100%;
	}
}

// Classes added via JavaScript

.nav-fixed {
	position: fixed;
	top:0;
	right:0;
	left:0;
	z-index: 9999;
}
.navbar-filler {
	height: 0;
	width: 100%;
}
