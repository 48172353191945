.whatsComingUp {
  background: #800000 url('../images/backgrounds/images/redSwooshes.jpg') repeat top center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding: 5rem 5px;
}
@media (min-width: 800px) {
  .whatsComingUp {
    background: #800000 url('../images/backgrounds/images/redSwooshes.jpg') repeat top center fixed;
  }
}
  .whatsComingUp::after {
    clear: both;
    content: "";
    display: table; }
  .whatsComingUp h2, .whatsComingUp p, .whatsComingUp a, .whatsComingUp ul, .whatsComingUp li {
    color: #FFF; }
  .whatsComingUp h2 {
    margin-bottom: 30px;
  }
  .whatsComingUp ul {
    display: block;
    list-style: none;
    padding: 0;
    float: left;
    width: 100%; }
  .whatsComingUp ul li {
    width: 100%;
    display: inline-block;
    margin: 0 0 30px;
  }
  .whatsComingUp ul li a {
    display: block;
    float: left;
    width: 100%;
    position: relative;;
  }
  .whatsComingUp .jumpAwayLink {
    text-align: right;
    margin-bottom: 0;
  }
  .whatsComingUp .eventTitle .item {
      font-family: "Effra W01", Helvetica, sans-serif;
      font-weight: 700;
    text-decoration: none;
      font-size: 1.025rem;
      line-height: 1em;
      padding-right: 5px;
      display: inline-block;; }
  .whatsComingUp .eventDate {
    text-transform: uppercase;
    width: 66px;
    height: 64px;
    margin: 0 0 0 0;
    float: left;
    background: #fff;
    color: #900;
    padding: 3px 0 0 0;
      transition: all .3s ease;
  }

  .whatsComingUp a:hover .eventDate {
    /*-webkit-transform: rotate(-2deg);
    -moz-transform: rotate(-2deg);
    -ms-transform: rotate(-2deg);
    -o-transform: rotate(-2deg);
    transform: rotate(-2deg);*/ }
  .whatsComingUp .eventTitle {
    float: right;
    display: inline-block;
    position: absolute;
    left: 0;
    padding: 7px 0 0 84px;
    right: 0;
    min-height: 67px;
    border-top: 2px solid transparent;
    top: -1px;
  }
  .whatsComingUp a:hover .eventTitle {
    border-color: #fff;
    background: rgba(0,0,0,0.05);
  }
    .whatsComingUp .eventDate span {
      display: block;
      text-align: center; }
      .whatsComingUp .eventDate span.eventDate_day {
        font-size: 2rem; }

@media (min-width: 601px) {
  .whatsComingUp ul li {
    float: left;
    clear: none;
    width: 50%;
    padding-right: 35px;
  }
  .whatsComingUp ul li:hover .eventDate {
    color: #b00;
  }
  .whatsComingUp ul li a .eventTitle {
    color: #f4f4f4;
  }
  .whatsComingUp ul li:hover a .eventTitle {
    color: #fff;
  }
  .boldMoves h2, .whatsComingUp h2 {
    float: left;
    width: 66%; }
  .whatsComingUp h2 {
    margin-bottom: 3rem; }
  .jumpAwayLink {
    float: right;
    width: 33%;
    line-height: 2rem;
    text-align: right;
    margin-top: 0.5rem; }
    .jumpAwayLink a {
      text-decoration: none;
      font-size: 1rem;
      color: #FFBDC0;
    }
      .jumpAwayLink a:hover {
        color: #fff;
        }
      }

@media (min-width: 1160px) {
  .whatsComingUp ul li {
    float: left;
    clear: none;
    width: 25%;
    padding-right: 35px;
  }
}
