////////////////////////
// Base Partials
//
// These files will be shared across all three of your output CSS files. Generally included here are only Compass Extension imports and imports for variables, functions, mixins, and extendables.
//
// Toolkit imports all of Compass, so there is no need to import it separately.
////////////////////////
////////////////////////
// Private Imports
////////////////////////

html {
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

.visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.position-relative {
    position: relative;
}

body{
  font-family: $font;
  color: #222;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;

}


@import "extendables";
@import "backgrounds";
@import "borders";
@import "box-model";
@import "colors";
