html, body {
    margin: 0px;
    height: 100%;
    background: #fff;
}

body {
    font-size: 16px;
}

#main-prefix, #main-postfix, #main-content {
    margin: 1em 0;
}

#main-prefix {
    h2 {
        font-size: 1.6em;
    }
    ul li {
        list-style: none;
        padding: 0px 0px 5px 0px;
        text-align: left;
        margin: 6px 0px 4px 0px;
        @include breakpoint($screen-lg) {
            border-bottom: 1px solid #ccc;
        }
    }
    ul ul {
        margin: 15px 0 0 10px;
        padding: 0;
        @include breakpoint($screen-lg) {
            border-top: 1px solid #ccc;
        }
    }
    ul li:last-child {
        @include breakpoint($screen-lg) {
            border-bottom: 0px solid #ccc;
        }
    }
    ul li a:after {
        display: inline-block;
        content: "\f105";
        margin: 0 0 0 8px;
        @extend .sbuicon;
        position: relative;
        z-index: 1;
        font-size: 1em;
        @include breakpoint($screen-lg) {
            content: "";
        }
    }
}

#right-prefix {
    margin-bottom: 2em;
}

#right-prefix, #right-postfix {
    h2 {
        font-size: 1.6em;
    }
    img {
        max-width: 100%;
        height: auto;
    }
}

#right-prefix > .panel-pane, #right-postfix > .panel-pane {
    margin: 1em 0;
}

.unity-sidebar-prefix
{
    #main-content
    {
     @include make-lg-column(9);
    @include make-lg-column-push(3);

    }
    #main-prefix
    {
         @include make-lg-column(3);
         @include make-lg-column-pull(9);

    }

    .container .container
    {
        width: 100%;
        max-width: 100%;
    }
}

@import "responsive-2col";
//@import "campaign-banners";
