.simple-two-col-table {
    margin: 10px 0;
}

.simple-two-col-table tr {
    vertical-align: top;
}

.simple-two-col-table td {
    padding: 5px 10px;
}

.simple-two-col-table td.cell-label {
    font-family: 'Trade Gothic W01 Bold 2';
}