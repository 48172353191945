#banner .flexslider li .caption-container {
    width: 100%;
    position: absolute;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    z-index: 1;
    color: #fff;
}

#banner .flexslider li .caption {
    padding: 10px;
    max-width: 1200px;
    margin: 0 auto;
}

#banner {
    background-color: #fff;

    .page-title {
        max-width: 1200px;
        margin: 0 auto;
        text-align: center;

        h1 {
            font-family: 'CenturyExpandedLTW01-Ro' !important;
            font-size: 3.3em;
            font-weight: 400;
            text-transform: none;
            color: #000;
            padding: 0 0.4em;
        }
    }

    .flexslider {
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        height: 100%;
    }

    .flexslider .slides img {
        height: auto;
    }

    .slides li {
        display: block;
        //width: 800px;
        //height: 368px;
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }
    /*
    .slides li:before
    {
      content:'\A';
        position:absolute;
        width:100%; height:100%;
        top:0; left:0;
        background:rgba(0,0,0,0.6);
        opacity:0;
        
    }*/

    /* the text box */
    .slides li .slide-text {
        position: relative;
        top: 0px;
        left: 0px;
        width: 100%;
        padding: 30px 35px;
        background: linear-gradient(rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)), no-repeat;
        height: 100%;
        overflow: hidden;
        color: #fff;
        font-family: 'Trade Gothic W01';
        font-weight: 200;
        font-style: normal;

        @include breakpoint($break) {
            width: 65%;
            background: -moz-linear-gradient(left, rgba(0, 0, 0, 0.65) 50%, rgba(0, 0, 0, 0) 100%); /* FF3.6+ */
            background: -webkit-gradient(linear, left top, right top, color-stop(50%, rgba(0, 0, 0, 0.65)), color-stop(100%, rgba(0, 0, 0, 0))); /* Chrome,Safari4+ */
            background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.65) 50%, rgba(0, 0, 0, 0) 100%); /* Chrome10+,Safari5.1+ */
            background: -o-linear-gradient(left, rgba(0, 0, 0, 0.65) 50%, rgba(0, 0, 0, 0) 100%); /* Opera 11.10+ */
            background: -ms-linear-gradient(left, rgba(0, 0, 0, 0.65) 50%, rgba(0, 0, 0, 0) 100%); /* IE10+ */
            background: linear-gradient(to right, rgba(0, 0, 0, 0.65) 50%, rgba(0, 0, 0, 0) 100%); /* W3C */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=1 ); /* IE6-9 */
        }

        @include breakpoint($break2) {
            width: 35%;
        }
    }

    .slides li .slide-text h1 {
        font-size: 22px;
        margin-bottom: 15px;
    }

    .slides li .slide-text p {
        border-top-color: rgb(255, 255, 255);
        border-top-style: solid;
        border-top-width: 1px;
        font-size: 14px;
        cursor: auto;
        display: block;
        font-family: 'Trade Gothic W01';
        font-weight: 200;
        font-style: normal;
        font-size: 14px;
        height: 110px;
        line-height: 22.3999996185303px;
        margin-top: 10px;
        padding-top: 10px;
        text-align: left;
        text-transform: uppercase;
    }

    .flex-control-nav {
        bottom: -60px;
    }

    .flex-control-nav li {
        margin: 0 2px;
    }

    /* the dots below the slider */
    .flex-control-paging li a {
        background-color: #e9e9e9;
        background-image: linear-gradient(to bottom, whitesmoke, #d9d9d9); /* add prefixed versions */
        background-repeat: repeat-x;
        box-shadow: inset 0 -1px 0 0 white, 0 1px 0 0 rgba(0, 0, 0, 0.1);
        border: 1px solid #ededed;
        height: 14px;
        width: 14px;
    }

    .flex-control-paging li a.flex-active {
        background: #ef3a34;
        border-color: #ef3a34;
        box-shadow: inset 0 -1px 0 0 rgba(255, 255, 255, 0.5), 0 1px 0 0 rgba(0, 0, 0, 0.25);
    }

    .flex-control-paging li a:hover {
        background: #015697;
        border-color: #015697;
        box-shadow: inset 0 -1px 0 0 rgba(255, 255, 255, 0.5), 0 1px 0 0 rgba(0, 0, 0, 0.25);
    }

    .flexslider .flex-direction-nav li a.flex-prev, .flexslider .flex-direction-nav li a.flex-next {
        display: block;
    }

    .flex-direction-nav a.flex-prev {
        margin: -25px 0 0 -78px;
        width: 78px;
        height: 78px;
        background: url("../assets/images/slider/left-icon.png");
    }

    .flex-direction-nav a.flex-next {
        margin: -25px -78px 0 0;
        width: 78px;
        height: 78px;
        background: url("../assets/images/slider/right-icon.png");
    }
}