.social-links {

  h3 {
    text-transform: uppercase;
    font-size: 1.5em;
    font-weight: 600;
  }

a.social-link {
    display: inline-block;
    padding: 10px 10px 0;
    margin: 0 4px;
    width: 42px;
    height: 42px;
    border-radius: 21px;
    border: 1px solid #383838;
    text-align: center;
    box-sizing: border-box;
    font-size: 1.4em;
    line-height: 0;
    text-shadow: 0 0 4px rgba(70, 70, 70, 0.3);
    color: #ccc;
    text-decoration: none;

}

a.social-link:hover {
    color: #fff;
    text-decoration: none;
}

a.social-link:before {
    margin-right: 0;
    font-family: 'sbu-icons';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Enable Ligatures ================ */
    -webkit-font-feature-settings: "liga";
    -moz-font-feature-settings: "liga=1";
    -moz-font-feature-settings: "liga";
    -ms-font-feature-settings: "liga" 1;
    -o-font-feature-settings: "liga";
    font-feature-settings: "liga";
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a.social-link--facebook {
    background-color: $facebook;
    //padding: 12px 12px 0;
}

a.social-link--facebook:before {
    content: "\f09a";
}

a.social-link--twitter {
    background-color: $twitter;
}

a.social-link--twitter:before {
    content: "\eeed";
}

a.social-link--youtube {
    background-color: $red;
}

a.social-link--youtube:before {
    content: "\eef3";
}

a.social-link--flickr {
    background-color: #0063dc;
}

a.social-link--flickr:before {
    content: "\f16e";
}

a.social-link--instagram {
    background-color: #3f729b;
}

a.social-link--instagram:before {
    content: "\f16d";
}

a.social-link--yammer {
    background-color: $yammer;
}

a.social-link--yammer:before {
    content: "\e601";
}
}
