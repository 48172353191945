.hero-contact-card {

    position: relative;
    background-size: cover;

    @include breakpoint($break-phone)
    {
        height: 450px;
    }

    .hero-wrap {
        position: relative;
        color: #fff;
        background: none;
        background-color: #fff;
        @include breakpoint($break-phone) {
            margin: 0;
            padding: 1em 2em;
            background: rgba(255, 255, 255, 0.4);
            color: #fff;
            display: block;
            position: absolute;
            bottom: 0;
            right: 0;
            width: 100%;
            background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 50%, rgba(0, 0, 0, 1) 100%);
            /* FF3.6+ */
            background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0)), color-stop(50%, rgba(0, 0, 0, 0.65)), color-stop(100%, rgba(0, 0, 0, 1)));
            /* Chrome,Safari4+ */
            background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 50%, rgba(0, 0, 0, 1) 100%);
            /* Chrome10+,Safari5.1+ */
            background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 50%, rgba(0, 0, 0, 1) 100%);
            /* Opera 11.10+ */
            background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 50%, rgba(0, 0, 0, 1) 100%);
            /* IE10+ */
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 50%, rgba(0, 0, 0, 1) 100%);
            /* W3C */
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#000000',GradientType=0 );
            /* IE6-9 */
        }

        .hero-content
        {
            max-width: 1200px;
            margin: 0 auto;

        }


        .contact-card
        {
            background-color: #fff;
            background-color: rgba(255,255,255,0.75);

            width: 100%;
            max-width: 800px;
            border: 1px solid #ccc;
            color: #000;
            float: left;

            font-size: 0.8em;

            @include breakpoint($break-tablet)
            {
                font-size: 1em;
            }

            .contact-card-image
            {
                img
                {
                    width:100%;
                    height: auto;
                }
            }

            .contact-card-text
            {

                float: left;

                 @include breakpoint($break-tablet)
                {
                    margin-left: 2em;
                }


                h1, h2
                {
                    margin-top: 0;
                }
                h1
                {
                    margin-bottom: 0.2em;
                }

                span:before
                {
                    margin-right: 0.7em;
                }
                span:after
                {
                    margin-right: 0.2em;
                }

                span
                {
                    margin-bottom: 0.2em;
                }
            }
        }

    }
}
