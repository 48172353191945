
/* ------------------- Carousel Styling ------------------- */

.carousel-inner {
  border-radius: 0px;

}

.carousel-caption {
  background-color: rgba(51,51,51,.5);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  padding: 30px 50px;
  color: #fff;
  text-align: left;
  width: 100%;

  p {
    font-size: 18px;
  }
}

@media screen and (max-width: 770px) {
    .carousel-caption {
        position: relative;
        width: 100%;
    }
}

.carousel-indicators {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  z-index: 15;
  margin: 0;
  text-align: right;
}

.carousel-control.left,
.carousel-control.right {
  background-image: none;
}
